<template>
	<div class="not-found mx-8 w-auto">
		<center>
			<small class="text-xl font-mono font-bold text-opacity-40">
				The page you requested for does not exist, although we have this awesome
				<strong>article</strong> you might want to read.
			</small>
		</center>
		<div class="grid grid-col-1">
			<FeaturedArticle />
		</div>
	</div>
</template>
<script>
//components import
import FeaturedArticle from "./../components/FeaturedArticle.vue";

export default {
	name: "NotFound",
	components: {
		FeaturedArticle,
	},
};
</script>
<style scoped></style>
